@font-face {
  font-family: 'Garamand-Bold';
  src: local('Garamand-Bold'), url(./components/fonts/AGaramondPro-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'Garamand';
  src: local('Garamand'), url(./components/fonts/AGaramondPro-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'Helvetica-Bold';
  src: local('Helvetica-Bold'),
      url(./components/fonts/HelveticaNeueCyr-Bold.eot?#iefix) format('embedded-opentype'),
      url(./components/fonts/HelveticaNeueCyr-Bold.woff2) format('woff2'),
      url(./components/fonts/HelveticaNeueCyr-Bold.woff) format('woff'),
      url(./components/fonts/HelveticaNeueCyr-Bold.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica-Bold";
}
   
@font-face {
  font-family: 'Helvetica';
  src: local('Helvetica'),
      url(./components//fonts/HelveticaNeueCyr-Medium.eot?#iefix) format('embedded-opentype'),
      url(./components/fonts/HelveticaNeueCyr-Medium.woff2) format('woff2'),
      url(./components/fonts/HelveticaNeueCyr-Medium.woff) format('woff'),
      url(./components/fonts/HelveticaNeueCyr-Medium.ttf) format('truetype');
  font-weight: 500;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica";
}
 